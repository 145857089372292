
import { defineComponent, ref, onMounted, inject } from 'vue';
import { Input, Select } from '@/components/input-elements';
import { useStore } from 'vuex';
import { Actions } from '@/store/enums/StoreEnums';
import { useI18n } from 'vue-i18n';
import { setPageHistory } from '@/core/helpers/toolbar';
import ExportModal from '@/components/modals/ExportModal.vue';
import ChangePriceModal from '../modals/ChangePriceModal.vue';

export default defineComponent({
    components: { Input, Select, ExportModal, ChangePriceModal },
    props: {
        id: { type: String, default: 'export' },
        idPrice: { type: String, default: 'price' },
        filter: { type: Boolean, default: false },
        clearInput: { type: Boolean, default: false },
        clearInputSelect: { type: Boolean, default: false },
        showExport: { type: Boolean, default: true },
        showButton: { type: Boolean, default: false },
        dateFilter: { type: Boolean, default: false },
        selectData: Object,
        filterPlaceholder: { type: String, default: 'message.SELECT' },
        inputPlaceholder: { type: String, default: 'message.SELECT' }
    },
    setup(props, context) {
        const inputValue = ref('');
        const selectValue = ref('');
        const store = useStore();
        const i18n = useI18n();
        const emitter: any = inject('emitter');
        const exportComponent = ref();
        const priceComponent = ref();

        const translate = (msg: string) => {
            if (msg.includes('message.')) {
                const splitKeys = msg.split(' ');
                splitKeys.forEach((item, index, array) => {
                    array[index] = i18n.t(item);
                });
                return splitKeys.join(' ');
            }
            return msg;
        };

        const translateFilters = (filters: Record<any, any>) => {
            const data: Record<any, any> = [];
            filters.forEach(filter => {
                data.push({
                    label: i18n.t(filter.label),
                    value: filter.value
                });
            });
            return data;
        };

        const searchHandler = () => {
            const data = {
                input: inputValue.value == undefined ? '' : inputValue.value,
                select: selectValue.value == undefined ? '' : selectValue.value
            };
            store.dispatch(Actions.SEARCH_HANDLER, {
                data: data
            });
            context.emit('search');
        };

        const exportHandler = () => {
            exportComponent.value.exportModal();
        };

        const priceHandler = () => {
            priceComponent.value.priceModal();
        };

        const exportFile = data => {
            context.emit('exportFile', data);
        };

        const reloadListing = () => {
            setPageHistory({});
            store.commit('SET_SEARCH_DATA', {});
            inputValue.value = '';
            selectValue.value = '';
            context.emit('reloadListing');
        };

        onMounted(() => {
            const searchedData = store.getters.getPageHistory;
            props.clearInput == false ? (inputValue.value = searchedData?.search_key) : (inputValue.value = '');
            props.clearInputSelect == false ? (selectValue.value = searchedData?.filter) : (selectValue.value = '');
        });

        return {
            inputValue,
            selectValue,
            translate,
            translateFilters,
            searchHandler,
            exportHandler,
            reloadListing,
            exportComponent,
            priceComponent,
            exportFile,
            priceHandler
        };
    }
});
